<template>
  <v-card
    dark
    color="primary"
  >
    <v-card-title>
      Performance Manager
      <v-spacer />
      <v-btn
        small
        :color="connected ? 'success' : 'error'"
      >
        {{ connected ? 'server connected' : 'server disconnected' }}
      </v-btn>

    </v-card-title>
    <v-card-text >
      <v-row>
        <v-col cols="5">
          <Chunklist
            :items="chunks.inactive"
            :total="getTotal"
            title="Inactive Chunks"
          ></Chunklist>
        </v-col>
        <v-col cols="2">
          <v-btn
            v-shortkey="['ctrl', 'space']"
            block
            color="success"
            class="next mb-2"
            @shortkey.native="nextChunk"
            @click="nextChunk()"
          >
            Next Chunk
            <v-icon
              x-large
              right
              class="pl-3"
            >
              mdi-arrow-right-bold-hexagon-outline
            </v-icon>
          </v-btn>
          <v-btn
            block
            color="secondary"
            class="prev"
            @click="prev($route.params.id)"
          >
            <v-icon
              left
              x-large
              class="pr-3"
            >
              mdi-arrow-left-bold-hexagon-outline
            </v-icon>
            Prev
          </v-btn>
        </v-col>
        <v-col cols="5">
          <Chunklist
            :items="chunks.active"
            :total="getTotal"
            title="Active Chunks"
          ></Chunklist>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Instantmessages/>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-list dense>
        <v-list-item
          v-for="(item, index) in clientsPerProfile"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title>
              Profile: {{ item.key }}
              Clients: {{ Object.keys(item.value.sockets).length }}
            </v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
    </v-card-actions>
  </v-card>
</template>

<script>

import Chunklist from '../components/PerformanceManager/Chunklist'
import {mapActions, mapMutations, mapState} from 'vuex'
import * as io from 'socket.io-client'
import _ from 'lodash'
import Instantmessages from '../components/PerformanceManager/Instantmessages'
import Vue from 'vue'

export default {
  name: 'PerformanceManager',
  components: {
    Instantmessages,
    Chunklist
  },
  data() {
    return {
      socket: null,
      clientsPerProfile: [],
      connected: false,
    }
  },
  computed: {
    ...mapState('performance', ['chunks']),

    getTotal() {
      return this.chunks.active.length + this.chunks.inactive.length
    }
  },
  mounted() {
    // this.setInstantmessageHostAndProjectId(this.$route.params.id);

    this.socket = null
    this.setProjectId(this.$route.params.id)
    this.loadChunks(this.$route.params.id)
    // this.loadInstantmessages(this.$route.params.id)
    this.loadInstantmessagesProject()

    const CONNECTIONSTRING = process.env.VUE_APP_IOCONNECTION || ''
    const socket = io.connect(CONNECTIONSTRING)
    this.socket = socket

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const th = this

    socket.on('connect', function () {
      th.connected = true
    })
    socket.on('disconnect', function () {
      th.connected = false
    })
    this.socket = socket

  },
  methods: {
    ...mapActions('instantmessage', ['loadInstantmessagesProject']),
    ...mapActions('performance', ['loadChunks', 'next', 'prev', 'loadInstantmessages']),
    ...mapMutations('performance', ['setProjectId']),

    nextChunk() {
      this.next(this.$route.params.id)
    },
  },


}
</script>

<style scoped>
.next {
  height: 240px !important;
}

.prev {
  height: 52px !important
}
</style>
