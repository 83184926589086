<template>
  <v-card
    max-height="300px"
    height="300px"
  >
    <v-toolbar dense>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pt-0 pr-0 pb-0">
      <v-virtual-scroll
        :items="items"
        :item-height="30"
        height="204px"
      >
        <template v-slot="{ item }">
          {{ item.partitionseq }}/{{ item.chunkseq }} - {{ item.title || ' n.a. ' }}
        </template>
      </v-virtual-scroll>
    </v-card-text>
    <v-toolbar dense>
      {{ items.length }}/{{ total }}
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  name: 'Chunklist',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    title: {
      type: String,
      default: () => {
        return ''
      }
    },
    total: {
      type: Number,
      default: () => {
        return 0
      }
    }
  }
}
</script>

<style scoped>

</style>
