<template>
  <v-card>
    <v-card-title>
      Instantmessages
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="instantmessages"
      >
        <template #item.active="{item}">
          <v-btn
            v-if="!item.active"
            icon
            @click="publishMessage(item)"
          >
            <v-icon color="secondary">
              mdi-eye-off
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            @click="unpublishMessage(item)"
          >
            <v-icon color="success">
              mdi-eye
            </v-icon>
          </v-btn>
        </template>

        <template #item.actions="{item}">
          <v-btn
            icon
            :disabled="item.active"
            @click="editInstantmessage(item)"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </template>

        <template #item.languages="{item}">
          {{ getLanguageCodes(item) }}
        </template>

        <template #item.description="{item}">
          <span v-text="getSubTest(item.description)"></span>
        </template>

        <template #item.active_timestamp="{item}">
          <span v-text="$dt(item.active_timestamp)" />
        </template>

      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        small
        color="primary"
        @click="createInstantmessage()"
      >
        new Message
      </v-btn>
      <v-btn
        depressed
        small
        color="primary"
        @click="createInstantmessageFromHostpool"
      >
        new message from host pool
      </v-btn>
    </v-card-actions>
    <InstantmessageDetail
      :visible="dialog_instantmessageDetail"
      :is-host="false"
      :data="selected_instantmessage"
      :is-new="isNew"
      @close="dialog_instantmessageDetail = false"
      @reload="loadInstantmessages"
    />
    <AddFromHostDialog
      :visible="dialog_addFromHost"
      @close="dialog_addFromHost = false"
      @reload="loadInstantmessages"
    />
  </v-card>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import _ from 'lodash'
import InstantmessageDetail from '../Admindashboard/Instantmessages/InstantmessageDetail'
import Instantmessage from '../../types/Instantmessage'
import AddFromHostDialog from '../Admindashboard/Instantmessages/AddFromHostDialog'
import instantmessageService from '@/services/Instantmessage.service'

export default {
  name: 'Instantmessages',
  components: {
    AddFromHostDialog,
    InstantmessageDetail
  },
  data: () => ({
    dialog_instantmessageDetail: false,
    dialog_addFromHost: false,
    isNew: false,
    selected_instantmessage: {},
    instantmessages: [],

    headers: [
      {
        text: '',
        value: 'active',
        sortable: false
      },
      {
        text: 'title',
        value: 'title'
      },
      {
        text: 'description',
        value: 'description'
      },
      {
        text: 'available languages',
        value: 'languages'
      },
      {
        text: 'published',
        value: 'active_timestamp'
      },
      {
        text: '',
        value: 'actions',
        align: 'right',
        sortable: false
      }
    ]
  }),

  computed: {
    ...mapState('admin', ['host_id']),
  },
  mounted() {
    this.loadInstantmessages()
  },
  methods: {
    async loadInstantmessages() {
      this.loading_instantmessages = true
      this.instantmessages = await instantmessageService.loadInstantmessagesFromProject(this.$route.params.id)
      this.loading_instantmessages = false
    },

    createInstantmessage() {
      this.selected_instantmessage = new Instantmessage(this.$route.params.id, this.host_id)
      this.isNew = true
      this.dialog_instantmessageDetail = true
    },

    editInstantmessage(item) {
      this.selected_instantmessage = _.clone(item)
      this.isNew = false
      this.dialog_instantmessageDetail = true
    },

    createInstantmessageFromHostpool() {
      this.dialog_addFromHost = true
    },

    async publishMessage(item) {
      await instantmessageService.publish(item.id)
      this.$toast('instantmessage published', {
        type: 'success',
        top: true,
        right: true,
        timer: 2000
      })
      await this.loadInstantmessages()
    },

    async unpublishMessage(item) {
      await instantmessageService.unpublish(item.id)
      this.$toast('instantmessage unpublished', {
        type: 'success',
        top: true,
        right: true,
        timer: 2000
      })
      await this.loadInstantmessages()
    },

    getLanguageCodes(item) {
      return _.map(item.instantmessageTranslations, 'language.code').join(', ')
    },

    getSubTest(text) {
      return _.truncate(text, {
        length: 100
      })
    }
  }
}
</script>

<style scoped>

</style>
