import axios from 'axios'
import store from '@/store'

class ProjectServiceClass {
  async loadProjects() {
    try {
      const {data} = await axios.get('/api/project/all')
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async loadProjectDetails(project_id) {
    try {
      const {data} = await axios.get(`/api/project/${project_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async createProject(project) {
    try {
      const {data} = await axios.post('/api/project', project)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async updateProject(project_id, project) {
    try {
      const {data} = await axios.put(`/api/project/${project_id}`, project)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async deleteProject(project_id) {
    try {
      const {data} = await axios.delete(`/api/project/${project_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }


  async loadTranslations(project_id) {
    try {
      const {data} = await axios.get(`/api/project/${project_id}/translations`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async createTranslation(project_id, payload) {
    try {
      const {data} = await axios.post(`/api/project/${project_id}/translation`, payload)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async updateTranslation(project_id, translation_id, payload) {
    try {
      const {data} = await axios.put(`/api/project/${project_id}/translation/${translation_id}`, payload)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async deleteTranslation(project_id, translation_id) {
    try {
      const {data} = await axios.delete(`/api/project/${project_id}/translation/${translation_id}`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }






  async loadUsersToProject(project_id) {
    try {
      const {data} = await axios.get(`/api/project/${project_id}/users`)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async addUserToProject(project_id, user_id) {
    try {
      const {data} = await axios.post(`/api/project/${project_id}/user/${user_id}`)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async deleteUserFromProject(project_id, user_id) {
    try {
      const {data} = await axios.delete(`/api/project/${project_id}/user/${user_id}`)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async updateVisibility(project_id, value) {
    try {
      const {data} = await axios.put(`/api/project/${project_id}/updateVisibility`, {active: value})
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async getProfiles(project_id) {
    try {
      const {data} = await axios.get(`/api/project/${project_id}/profiles`)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

}

const projectService = new ProjectServiceClass()
export default projectService
