var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Instantmessages ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.instantmessages},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [(!item.active)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.publishMessage(item)}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-eye-off ")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.unpublishMessage(item)}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-eye ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":item.active},on:{"click":function($event){return _vm.editInstantmessage(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}},{key:"item.languages",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLanguageCodes(item))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.getSubTest(item.description))}})]}},{key:"item.active_timestamp",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.$dt(item.active_timestamp))}})]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":function($event){return _vm.createInstantmessage()}}},[_vm._v(" new Message ")]),_c('v-btn',{attrs:{"depressed":"","small":"","color":"primary"},on:{"click":_vm.createInstantmessageFromHostpool}},[_vm._v(" new message from host pool ")])],1),_c('InstantmessageDetail',{attrs:{"visible":_vm.dialog_instantmessageDetail,"is-host":false,"data":_vm.selected_instantmessage,"is-new":_vm.isNew},on:{"close":function($event){_vm.dialog_instantmessageDetail = false},"reload":_vm.loadInstantmessages}}),_c('AddFromHostDialog',{attrs:{"visible":_vm.dialog_addFromHost},on:{"close":function($event){_vm.dialog_addFromHost = false},"reload":_vm.loadInstantmessages}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }