<template>
  <v-dialog
      :value="visible"
      persistent
      max-width="500px"
  >
    <v-card>
      <v-card-title>
        Copy Instantmessage to Project
      </v-card-title>
      <v-card-text>
        <v-form ref="copy">
          <v-select
              v-model="selected_instantmessage"
              :items="instantmessages_host"
              item-text="title"
              item-value="id"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            depressed
            small
            color="primary"
            @click="save"
        >
          Add Instantmessage
        </v-btn>
        <v-btn
            depressed
            small
            color="primary"
            @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import instantmessageService from '@/services/Instantmessage.service'

export default {
  name: 'AddFromHostDialog',
  props: {
    visible: Boolean,
  },
  data: () => ({
    selected_instantmessage: null,
    instantmessages_host: [],
  }),

  computed: {},
  watch: {
    visible(val) {
      if (val) {
        this.loadInstantmessagesFromHost()
      }
    },
  },
  methods: {
    async loadInstantmessagesFromHost() {
      this.instantmessages_host = await instantmessageService.loadInstantmessagesFromHost()
    },

    async save() {
      await instantmessageService.addInstantmessageFromHostpool(this.$route.params.id, this.selected_instantmessage)
      this.$emit('reload')
      this.close()
    },

    close() {
      this.$refs.copy.reset()
      this.$emit('close')
    },
  }
}
</script>

<style scoped>

</style>
